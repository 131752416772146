import './TypeTester.scss';

import React, { useState } from 'react';

import * as Core from 'components/core';

interface Props {
  familyName: string;
  styles: {
    id: string;
    name: string;
    weight: string;
    is_italic: boolean;
    family_slug: string;
    opentype_features: string[];
    css: { [key: string]: any };
  }[];
  testerConfiguration: {
    defaultSize: number;
    defaultStyle: string;
    sizes: number[];
  };
}

const TypeTester: React.FC<Props> = (props) => {
  const { familyName, styles, testerConfiguration } = props;

  const [selectedStyle, setSelectedStyle] = useState(
    () =>
      styles.find((style) => style.id === testerConfiguration.defaultStyle) ||
      styles[0]
  );
  const [selectedSize, setSelectedSize] = useState<number>(
    testerConfiguration.defaultSize
  );

  const contentStyle = {
    fontFamily: `${familyName}Tester`,
    fontSize: `${selectedSize}px`,
    fontStyle: selectedStyle.css.style,
    fontWeight: selectedStyle.css.weight
  };

  const selectStyle = (styleID: string) => {
    setSelectedStyle(styles.find((style) => style.id === styleID)!);
  };

  return (
    <div className="TypeTester">
      <div className="TypeTester-wrapper">
        <div
          className="TypeTester-content"
          contentEditable
          style={contentStyle}
          suppressContentEditableWarning={true}
          onPaste={(event) => {
            event.preventDefault();

            const text = event.clipboardData.getData('text/plain');
            if (document.queryCommandSupported('insertText')) {
              document.execCommand('insertText', false, text);
            } else {
              document.execCommand('paste', false, text);
            }
          }}
        >
          Type something!
        </div>
      </div>

      <div className="TypeTester-controls">
        <Core.Select label={selectedStyle.name}>
          <select
            onChange={(event) => selectStyle(event.currentTarget.value)}
            value={selectedStyle.id}
          >
            {styles.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </Core.Select>

        <Core.Select label={selectedSize.toString()}>
          <select
            onChange={(event) =>
              setSelectedSize(parseInt(event.currentTarget.value))
            }
            value={selectedSize}
          >
            {testerConfiguration.sizes.map((size) => (
              <option key={size} value={size.toString()}>
                {size}
              </option>
            ))}
          </select>
        </Core.Select>
      </div>
    </div>
  );
};

export default TypeTester;
