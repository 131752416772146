declare global {
  interface Window {
    Components: any;
  }
}

require('styles/app.scss');

require('react_ujs');

import Family from 'components/Family';
import FamilyBuy from 'components/FamilyBuy';
import FamilyName from 'components/FamilyName';
import FamilyPricingLoader from 'components/family/pricing/Loader';
import Header from 'components/header/Header';
import TypeTester from 'components/TypeTester';

window.Components = {
  Family,
  FamilyBuy,
  FamilyName,
  FamilyPricingLoader,
  Header,
  TypeTester
};
