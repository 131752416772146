import './FamilyBuy.scss';

import React from 'react';

import SquircleHeader from 'components/SquircleHeader';
import DetailRow from 'components/DetailRow';
import TwoColumnRow from 'components/TwoColumnRow';

import FamilyType from 'components/family/FamilyType';
import MoreDetails from 'components/family/MoreDetails';

interface Props {
  family: FamilyType;
}

const FamilyBuy: React.FC<Props> = (props) => {
  const { family } = props;
  return (
    <div>
      <div className="FamilyBuy-wrapper">
        <div
          className="Family-detailsBlock"
          style={{ backgroundColor: family.colors.accent }}
        >
          <SquircleHeader
            content="details"
            backgroundColor={family.colors.text}
            textColor={family.colors.background}
          />

          <div className="Family-detailsBlock-feature">
            {family.details.map((detail) => (
              <DetailRow
                key={detail.id}
                label={detail.name}
                textColor={family.colors.background}
              >
                <TwoColumnRow
                  rowOne={
                    detail.disabledImageUrl ? (
                      <img
                        className="Family-detailsBlock-feature-image"
                        src={detail.disabledImageUrl}
                        style={{ height: family.detailHeight }}
                      />
                    ) : null
                  }
                  rowTwo={
                    detail.enabledImageUrl ? (
                      <img
                        className="Family-detailsBlock-feature-image"
                        src={detail.enabledImageUrl}
                        style={{ height: family.detailHeight }}
                      />
                    ) : null
                  }
                />
              </DetailRow>
            ))}

            <DetailRow
              label="supported languages"
              textColor={family.colors.background}
            >
              <div className="Family-detailsBlock-supportedLanguages">
                {family.languages}
              </div>
            </DetailRow>

            <MoreDetails family={family} />
          </div>
        </div>
      </div>

      <div className="FamilyBuy-wrapper">
        <div className="Family-rule" style={{ background: family.colors.text }}>
          <SquircleHeader
            content="buy it"
            backgroundColor={family.colors.text}
            textColor={family.colors.background}
          />
        </div>
      </div>
      {/* TODO: Refactor type tester styles */}
      <style
        dangerouslySetInnerHTML={{
          __html: `

            body {
              background: ${family.colors.background};
              color: ${family.colors.text};
            }

            footer {
              color: ${family.colors.text};
            }

            footer {
              border-color: ${family.colors.text};
            }


            .TypeTester-content {
              color: ${family.colors.text} !important;
            }


            .FamilyPricing,
            .FamilyPricing .CoreCheckbox .CoreCheckbox-input::before {
              color: ${family.colors.text};
            }

            .FamilyLicense {
              color: ${family.colors.background};
              background: ${family.colors.text};
            }

            .FamilyLicense .CoreSelect {
              border-color: ${family.colors.background};
            }

            .FamilyPricing-section-heading,
            .FamilyPricing-note,
            .FamilyPricing .CoreCheckbox .CoreCheckbox-input {
              border-color: ${family.colors.text};
            }

            .FamilyPricing .CoreCheckbox:hover {
              background: ${family.colors.accent};
            }

            .FamilyPricing-actions button {
              background: ${family.colors.text};
              color: ${family.colors.background};
            }

            .FamilyPricing-actions button:hover {
              background: ${family.colors.accent};
              color: ${family.colors.text};
            }
            
          `
        }}
      />
    </div>
  );
};

export default FamilyBuy;
