import './Select.scss';

import React from 'react';

interface Props {
  label: string;
  children: React.ReactNode;
}

const Select: React.FC<Props> = (props) => {
  const { label, children } = props;

  return (
    <span className="CoreSelect">
      {children}
      <span className="CoreSelect-button">
        <span className="CoreSelect-label">{label}</span>
        <span className="CoreSelect-arrow" />
      </span>
    </span>
  );
};

export default Select;
