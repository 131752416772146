import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 22.4 22.4"
      xmlSpace="preserve"
      className="close-icon"
    >
      <polygon
        points="22.4,1.4 21,0 11.2,9.8 1.4,0 0,1.4 9.8,11.2 0,21 1.4,22.4 11.2,12.6 21,22.4 22.4,21
	12.6,11.2 "
      />
    </svg>
  );
};

export default Logo;
