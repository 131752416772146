import { useState, useEffect } from 'react';

export const useFetchJSON = <T>(url: RequestInfo) => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(url, { signal })
      .then((response) => response.json())
      .then((data: T) => {
        setData(data);
      });

    return () => {
      controller.abort();
    };
  }, [url]);

  return { data };
};
