import './SquircleHeader.scss';

import React from 'react';

interface Props {
  content: string;
  backgroundColor: string;
  textColor: string;
}

const SquircleHeader: React.FC<Props> = (props) => {
  return (
    <div className="SquircleHeader">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 149.3 100"
        className="SquircleHeader-shape"
      >
        <path
          fill={props.backgroundColor}
          d="M74.6,0C22.1,0,0,21,0,50c0,29,22.1,50,74.6,50c52.5,0,74.7-21,74.7-50C149.3,21,127.1,0,74.6,0"
        />
      </svg>
      <div className="SquircleHeader-text" style={{ color: props.textColor }}>
        {props.content}
      </div>
    </div>
  );
};

export default SquircleHeader;
