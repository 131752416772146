import './Pricing.scss';

import React, { useState } from 'react';
import { forEach } from 'lodash';
import numeral from 'numeral';

import * as Core from 'components/core';

import { PricingData } from './DataTypes';
import { useLicensePricing } from './useLicensePricing';
import {
  licenseTypes,
  LicenseOptions,
  LicenseTypeID,
  LicenseOption
} from './LicenseTypes';

import License from './License';

function formatPrice(value: number): string {
  if (value == null || value === 0) {
    return '';
  }

  return numeral(value).format('$0,0');
}

interface Props {
  familySlug: string;
  pricingData: PricingData;
}

const FamilyPricing: React.FC<Props> = (props) => {
  const { pricingData } = props;
  const { collections, family, licenseParameters, styles } = pricingData;

  const [selectedParameters, setSelectedParameters] = useState<LicenseOptions>({
    desktop: {
      workstations: licenseParameters.desktop.workstations[0]
    },
    webfont: {
      domains: licenseParameters.webfont.domains[0],
      views: licenseParameters.webfont.views[0]
    },
    mobile: {
      apps: licenseParameters.mobile.apps[0]
    }
  });

  const onSelectParameter = (
    licenseTypeID: LicenseTypeID,
    attribute: 'workstations' | 'views' | 'apps',
    id: number
  ) => {
    setSelectedParameters((previousSelectedParameters) => ({
      ...previousSelectedParameters,
      [licenseTypeID]: {
        ...previousSelectedParameters[licenseTypeID],
        [attribute]: licenseParameters[licenseTypeID][attribute].find(
          (option: LicenseOption) => option.id === id
        )
      }
    }));
  };

  const pricing = useLicensePricing(pricingData);

  const formData = () => {
    const data = [{ name: 'cart_item[family_slug]', value: family.slug }];

    for (const licenseType of pricing.enabledLicenses) {
      for (const parameter in selectedParameters[licenseType]) {
        const option = selectedParameters[licenseType][parameter];
        data.push({
          name: `cart_item[${licenseType}_parameters][${parameter}]`,
          value: option.id
        });
      }
    }

    return data;
  };

  const collectionPrice = function (collectionId: string) {
    return formatPrice(
      pricing.collectionPrice(selectedParameters, collectionId)
    );
  };

  const stylePrice = function (styleId: string) {
    return formatPrice(pricing.stylePrice(selectedParameters, styleId));
  };

  const totalPrice = function () {
    return formatPrice(pricing.totalPrice(selectedParameters));
  };

  return (
    <div className="FamilyPricing">
      <div className="FamilyPricing-row">
        <div className="FamilyPricing-section">
          <h3 className="FamilyPricing-section-heading">Collections</h3>

          {collections.map((collection) => (
            <Core.Checkbox
              key={collection.id}
              name="collection"
              checked={pricing.collectionIsSelected(collection.id)}
              onChange={() => pricing.toggleCollection(collection.id)}
              style={
                pricing.collectionIsSelected(collection.id)
                  ? { backgroundColor: family.colors.background }
                  : {}
              }
            >
              <span>
                {family.name} {collection.name}
              </span>
              <span className="FamilyPricing-item-price">
                {collectionPrice(collection.id)}
              </span>
            </Core.Checkbox>
          ))}
        </div>

        <div className="FamilyPricing-section">
          <h3 className="FamilyPricing-section-heading">Styles</h3>

          {styles.map((style) => (
            <Core.Checkbox
              key={style.id}
              name="cart_item[style_ids][]"
              value={style.id}
              checked={pricing.styleIsSelected(style.id)}
              onChange={() => {
                pricing.toggleStyle(style.id);
              }}
              style={
                pricing.styleIsSelected(style.id)
                  ? { backgroundColor: family.colors.background }
                  : {}
              }
            >
              <span>
                {family.name} {style.name}
              </span>
              <span className="FamilyPricing-item-price">
                {stylePrice(style.id)}
              </span>
            </Core.Checkbox>
          ))}
        </div>

        <div className="FamilyPricing-section">
          <h3 className="FamilyPricing-section-heading">License Types</h3>

          {licenseTypes.map((licenseType) => (
            <License
              key={licenseType.id}
              licenseType={licenseType}
              pricing={pricing}
              parameters={licenseParameters}
              selectedParameters={selectedParameters}
              onSelectParameter={(
                attribute: 'workstations' | 'views' | 'apps',
                id: number
              ) => onSelectParameter(licenseType.id, attribute, id)}
            />
          ))}

          <div className="FamilyPricing-note">
            All licenses are perpetual. Need a different license type? Have a
            question? <a href="/contact">Get in touch!</a>
          </div>
          <div className="FamilyPricing-total" data-ref="total-price">
            {totalPrice()}
          </div>

          <div className="FamilyPricing-actions">
            <button
              data-ref="add-to-cart"
              disabled={!pricing.isValid()}
              type="submit"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>

      {formData().map((datum) => (
        <input
          type="hidden"
          key={datum.name}
          name={datum.name}
          value={datum.value}
        />
      ))}
    </div>
  );
};

export default FamilyPricing;
