import './License.scss';

import React from 'react';
import classNames from 'classnames';

import * as Core from 'components/core';

import { LicenseParameters } from './DataTypes';
import { LicensePricing } from './useLicensePricing';
import { LicenseType, LicenseOption, LicenseOptions } from './LicenseTypes';

interface Props {
  licenseType: LicenseType;
  pricing: LicensePricing;
  parameters: LicenseParameters;
  selectedParameters: LicenseOptions;
  onSelectParameter(attribute: string, value: number): void;
}

const License: React.FC<Props> = (props) => {
  const {
    licenseType,
    pricing,
    parameters,
    selectedParameters,
    onSelectParameter
  } = props;

  const availableParameters = parameters[licenseType.id];
  const selectedLicenseOptions = selectedParameters[licenseType.id];

  return (
    <div
      data-ref={`license-type-${licenseType.id}`}
      data-enabled={pricing.licenseIsEnabled(licenseType.id)}
      className={classNames('FamilyLicense', {
        'FamilyLicense--expanded': pricing.licenseIsEnabled(licenseType.id)
      })}
    >
      <div className="FamilyLicense-heading">
        <button
          data-ref="license-toggle"
          className="FamilyLicense-toggle"
          type="button"
          onClick={() => pricing.toggleLicense(licenseType.id)}
        />{' '}
        {licenseType.label}
      </div>

      <div className="FamilyLicense-options">
        {Object.keys(availableParameters).map((parameter) =>
          parameter === 'domains' ? (
            <input
              key={parameter}
              type="hidden"
              value={availableParameters[parameter][0].id.toString()}
            />
          ) : (
            <div key={parameter}>
              <Core.Select
                label={selectedLicenseOptions[parameter]?.description}
              >
                <select
                  data-ref={`parameter-${licenseType.id}-${parameter}`}
                  value={
                    selectedLicenseOptions[parameter]
                      ? selectedLicenseOptions[parameter].toString()
                      : ''
                  }
                  onChange={(event) =>
                    onSelectParameter(
                      parameter,
                      parseInt(event.currentTarget.value)
                    )
                  }
                >
                  {availableParameters[parameter].map(
                    (option: LicenseOption) => (
                      <option key={option.id} value={option.id.toString()}>
                        {option.description}
                      </option>
                    )
                  )}
                </select>
              </Core.Select>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default License;
