import { useEffect, useRef, useCallback } from 'react';

export const useThrottledScroll = (callback: () => void) => {
  const requestID = useRef<number>();

  const throttledCallback = useCallback(() => {
    if (requestID.current != null) {
      cancelAnimationFrame(requestID.current);
    }

    requestID.current = requestAnimationFrame(callback);
  }, [callback]);

  useEffect(() => {
    window.addEventListener('scroll', throttledCallback);

    return () => {
      if (requestID.current != null) {
        cancelAnimationFrame(requestID.current);
      }

      window.removeEventListener('scroll', throttledCallback);
    };
  }, [throttledCallback]);
};
