import './Checkbox.scss';

import React from 'react';
import classNames from 'classnames';

interface Props
  extends React.DetailedHTMLProps<
    React.HtmlHTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  children: React.ReactNode;
  checked: boolean;
  name?: string;
  value?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<Props> = (props) => {
  const { children, checked, name, value, onChange, ...labelProps } = props;

  return (
    <label
      className={classNames('CoreCheckbox', {
        'CoreCheckbox--checked': checked
      })}
      {...labelProps}
      data-ref="checkbox"
    >
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="CoreCheckbox-input" />
      <span className="CoreCheckbox-label">{children}</span>
    </label>
  );
};

export default Checkbox;
