import { useCallback, useMemo, useState } from 'react';
import {
  compact,
  curry,
  difference,
  every,
  find,
  get,
  map,
  reduce,
  union,
  without
} from 'lodash';

import { PricingData, Collection, BasePricing } from './DataTypes';
import { LicenseTypeID, LicenseTypeIDs, LicenseOptions } from './LicenseTypes';

const _multiplierForLicenseType = curry(function (
  enabledLicenses: string[],
  options: LicenseOptions,
  licenseType: LicenseTypeID
) {
  if (enabledLicenses.indexOf(licenseType) === -1) {
    return 0;
  }

  const multipliers = map(get(options, licenseType, {}), 'multiplier');

  return reduce(
    multipliers,
    function (total, eachMultiplier) {
      return total * eachMultiplier;
    },
    1
  );
});

const _priceForLicenseType = curry(function (
  basePricing: BasePricing,
  enabledLicenses: string[],
  options: LicenseOptions,
  stylesCount: number,
  licenseType: LicenseTypeID
) {
  const multiplier = _multiplierForLicenseType(
    enabledLicenses,
    options,
    licenseType
  );

  return basePricing[stylesCount] * multiplier;
});

// TODO: This was refactored from a class with relatively minimal changes. It
// could be further refactored to follow better hooks patterns.

export function useLicensePricing(data: PricingData) {
  const [selectedStyleIds, setSelectedStyleIds] = useState<string[]>([]);
  const [enabledLicenses, setEnabledLicenses] = useState<string[]>(['desktop']);
  const [basePricing] = useState<BasePricing>(data.basePricing);
  const [collections] = useState<Collection[]>(data.collections);

  // Private

  const _additionalBasePrice = useCallback(
    (stylesCount: number, additionalStylesCount: number) => {
      return basePricing[stylesCount + additionalStylesCount];
    },
    [basePricing]
  );

  const _licensePrices = useCallback(
    (options: LicenseOptions, stylesCount: number) => {
      const priceForLicenseType = _priceForLicenseType(
        basePricing,
        enabledLicenses,
        options,
        stylesCount
      );

      return compact(map(LicenseTypeIDs, priceForLicenseType));
    },
    [basePricing, enabledLicenses]
  );

  const _additionalLicensePrices = useCallback(
    (
      options: LicenseOptions,
      stylesCount: number,
      additionalStylesCount: number
    ) => {
      const additionalBasePrice = _additionalBasePrice(
        stylesCount,
        additionalStylesCount
      );
      const multiplierForLicenseType = _multiplierForLicenseType(
        enabledLicenses,
        options
      );

      return compact(
        map(LicenseTypeIDs, (licenseType: LicenseTypeID) => {
          return additionalBasePrice * multiplierForLicenseType(licenseType);
        })
      );
    },
    [_additionalBasePrice, enabledLicenses]
  );

  const _price = useCallback(
    (
      licenseOptions: LicenseOptions,
      stylesCount: number,
      additionalStylesCount = 0
    ): number => {
      let formattedPrice;
      let prices;

      if (additionalStylesCount > 0) {
        prices = _additionalLicensePrices(
          licenseOptions,
          stylesCount,
          additionalStylesCount
        );
      } else {
        prices = _licensePrices(licenseOptions, stylesCount);
      }

      const sortedPrices = prices.sort(function (a, b) {
        return b - a;
      });

      if (sortedPrices.length === 0) {
        formattedPrice = 0;
      } else if (sortedPrices.length === 1) {
        formattedPrice = Math.ceil(sortedPrices[0]);
      } else if (sortedPrices.length === 2) {
        formattedPrice = Math.ceil(sortedPrices[1] * 0.8 + sortedPrices[0]);
      } else {
        const valA = sortedPrices.shift()!;
        const valB = sortedPrices.pop()! * 0.8;
        const valC = reduce(
          sortedPrices,
          function (sum, num) {
            return sum + num * 0.95;
          },
          0
        );

        formattedPrice = Math.ceil(valA + valB + valC);
      }

      if (additionalStylesCount > 0) {
        return formattedPrice - _price(licenseOptions, stylesCount, 0);
      } else {
        return formattedPrice;
      }
    },
    [_additionalLicensePrices, _licensePrices]
  );

  // Public

  const isValid = useCallback((): boolean => {
    return selectedStyleIds.length > 0 && enabledLicenses.length > 0;
  }, [enabledLicenses.length, selectedStyleIds.length]);

  const licenseIsEnabled = useCallback(
    (licenseType: LicenseTypeID): boolean => {
      return enabledLicenses.indexOf(licenseType) > -1;
    },
    [enabledLicenses]
  );

  const styleIsSelected = useCallback(
    (styleId: string): boolean => {
      return selectedStyleIds.indexOf(styleId) > -1;
    },
    [selectedStyleIds]
  );

  const collectionIsSelected = useCallback(
    (collectionId: string): boolean => {
      const collection = find(collections, { id: collectionId })!;

      return every(collection.styles, (styleId: string) => {
        return selectedStyleIds.indexOf(styleId) > -1;
      });
    },
    [collections, selectedStyleIds]
  );

  const toggleCollection = useCallback(
    (collectionId: string): void => {
      const collection = find(collections, { id: collectionId })!;

      if (collectionIsSelected(collectionId)) {
        setSelectedStyleIds(difference(selectedStyleIds, collection.styles));
      } else {
        setSelectedStyleIds(union(selectedStyleIds, collection.styles));
      }
    },
    [collectionIsSelected, collections, selectedStyleIds]
  );

  const toggleStyle = useCallback(
    (styleId: string): void => {
      if (styleIsSelected(styleId)) {
        setSelectedStyleIds(without(selectedStyleIds, styleId));
      } else {
        setSelectedStyleIds(selectedStyleIds.concat([styleId]));
      }
    },
    [selectedStyleIds, styleIsSelected]
  );

  const toggleLicense = useCallback(
    (licenseType: LicenseTypeID) => {
      if (licenseIsEnabled(licenseType)) {
        setEnabledLicenses(without(enabledLicenses, licenseType));
      } else {
        setEnabledLicenses([...enabledLicenses, licenseType]);
      }
    },
    [enabledLicenses, licenseIsEnabled]
  );

  const totalPrice = useCallback(
    (licenseOptions: LicenseOptions) => {
      return _price(licenseOptions, selectedStyleIds.length);
    },
    [_price, selectedStyleIds.length]
  );

  const collectionPrice = useCallback(
    (licenseOptions: LicenseOptions, collectionId: string) => {
      if (collectionIsSelected(collectionId)) {
        return 0;
      } else {
        const collection = find(collections, { id: collectionId })!;

        const additionalStylesCount =
          collection.styles.length - selectedStyleIds.length;

        return _price(
          licenseOptions,
          selectedStyleIds.length,
          additionalStylesCount
        );
      }
    },
    [_price, collectionIsSelected, collections, selectedStyleIds.length]
  );

  const stylePrice = useCallback(
    (licenseOptions: LicenseOptions, styleId: string) => {
      if (styleIsSelected(styleId)) {
        return 0;
      } else {
        return _price(licenseOptions, selectedStyleIds.length, 1);
      }
    },
    [_price, selectedStyleIds.length, styleIsSelected]
  );

  return useMemo(
    () => ({
      isValid,
      enabledLicenses,
      licenseIsEnabled,
      styleIsSelected,
      collectionIsSelected,
      toggleCollection,
      toggleStyle,
      toggleLicense,
      totalPrice,
      collectionPrice,
      stylePrice
    }),
    [
      collectionIsSelected,
      collectionPrice,
      enabledLicenses,
      isValid,
      licenseIsEnabled,
      styleIsSelected,
      stylePrice,
      toggleCollection,
      toggleLicense,
      toggleStyle,
      totalPrice
    ]
  );
}

export type LicensePricing = ReturnType<typeof useLicensePricing>;
