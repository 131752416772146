import './Family.scss';

import React from 'react';

import SquircleHeader from 'components/SquircleHeader';
import Markdown from 'components/core/Markdown';

import FamilyType from 'components/family/FamilyType';

interface Props {
  family: FamilyType;
}

const Family: React.FC<Props> = (props) => {
  const { family } = props;

  return (
    <div
      className="Family"
      style={{
        backgroundColor: family.colors.background,
        color: family.colors.text
      }}
    >
      <div className="Family-wrapper">
        <div className="Family-masthead">
          <div className="Family-masthead-wrapper">
            {family.mastheadImageUrl && (
              <img
                className="Family-masthead-wrapper-image"
                src={family.mastheadImageUrl}
              />
            )}
            <div className="Family-masthead-wrapper-badge">
              <div className="Family-masthead-wrapper-badge-links">
                {/* TODO: Refactor type tester control styles */}
                <style
                  dangerouslySetInnerHTML={{
                    __html: `
                      .Family-masthead-wrapper-badge-links-buy:before,
                      .Family-masthead-wrapper-badge-links-buy:after {
                        background: ${family.colors.text};
                      }

                      .Family-masthead-wrapper-badge-links-small:hover {
                        color: ${family.colors.background};
                      }

                      .Family-masthead-wrapper-badge-links-buy:hover svg path {
                        fill: ${family.colors.background};
                      }

                      .Family-rAndDRule {
                        border-top-color: ${family.colors.text};
                      }
                      
                      .Family-rAndDMoreInfo {
                        color: ${family.colors.text};
                      }

                      .TypeTester-controls .CoreSelect {
                        background: ${family.colors.text};
                        color: ${family.colors.background};
                      }
                    `
                  }}
                />
                <a
                  className="Family-masthead-wrapper-badge-links-small"
                  href="#try-it"
                >
                  TRY
                </a>
                <a
                  className="Family-masthead-wrapper-badge-links-buy"
                  href="#buy-it"
                >
                  <svg width="100%" height="100%" viewBox="0 0 89.9 29">
                    <path
                      fill={family.colors.text}
                      d="M0,28.3V0h13.8c6.7,0,9.5,3,9.5,7.3c0,3.2-2.1,5.7-6.2,6.5c4.9,0.6,7,3.3,7,6.9c0,4.9-3.9,7.6-10.4,7.6H0z
                       M7.8,11.5h3.8c2.7,0,3.8-1.1,3.8-3.4c0-2.3-1.1-3.4-3.8-3.4H7.8V11.5z M11.8,23.6c3,0,4.3-1.1,4.3-3.7s-1.3-3.7-4.3-3.7h-4v7.4
                      H11.8z"
                    />
                    <path
                      fill={family.colors.text}
                      d="M44.3,23.2c2.7,0,4.3-1.5,4.3-5.1V0H56v17C56,24.4,52.1,29,44.1,29c-8,0-11.9-4.5-11.9-11.9V0H40v18.2
                      C40,21.7,41.6,23.2,44.3,23.2z"
                    />
                    <path
                      fill={family.colors.text}
                      d="M72.9,28.3v-9.2L63.6,0H72l5.4,12l5.4-12h7.2l-9.3,19.2v9.2H72.9z"
                    />
                  </svg>
                </a>
                {family.specimenPDFUrl && (
                  <a
                    className="Family-masthead-wrapper-badge-links-small"
                    href={family.specimenPDFUrl}
                  >
                    PDF
                  </a>
                )}
              </div>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 179.6 179.9"
                className="Family-masthead-wrapper-badge-shape"
              >
                <polygon
                  fill={family.colors.accent}
                  points="105.4,1.3 110.5,17.8 123.5,6.5 125,23.7 140.1,15.3 138,32.5 154.5,27.4 148.9,43.8 166.1,42.3
          157.2,57.1 174.4,59.2 162.6,71.8 178.9,77.4 164.7,87.3 179.6,96.2 163.6,103 176.3,114.8 159.3,118 169.2,132.2 152,131.9
          158.7,147.8 141.9,143.9 145.2,160.9 129.5,153.5 129.2,170.8 115.4,160.4 111.6,177.3 100.2,164.2 92.9,179.9 84.5,164.8
          74.2,178.6 69.1,162 56.1,173.4 54.6,156.2 39.5,164.6 41.6,147.4 25,152.5 30.7,136.1 13.5,137.6 22.4,122.8 5.2,120.7 17,108.1
          0.7,102.5 14.8,92.6 0,83.7 15.9,76.9 3.3,65.1 20.2,61.8 10.3,47.7 27.6,48 20.8,32.1 37.7,36 34.4,19 50,26.3 50.3,9.1 64.1,19.5
          68,2.6 79.3,15.7 86.6,0 95,15.1 "
                />
              </svg>
            </div>
          </div>
          <div className="Family-masthead-description">
            {family.description && <Markdown>{family.description}</Markdown>}
          </div>
        </div>

        {family.rAndD && (
          <div className="Family-rAndD">
            <div
              className="Family-rule"
              style={{ background: family.colors.text }}
            ></div>
            <h3 className="Family-rAndDHeading">This is a R&amp;D Typeface</h3>
            {family.rAndDNotes && (
              <div className="Family-rAndDDescription">
                <Markdown>{family.rAndDNotes}</Markdown>
              </div>
            )}
            <a href="/rnd" className="Family-rAndDMoreInfo">
              More about R&amp;D Projects...
            </a>
          </div>
        )}

        <div
          className="Family-stylesBlock"
          style={{ backgroundColor: family.colors.accent }}
        >
          <SquircleHeader
            content="styles"
            backgroundColor={family.colors.text}
            textColor={family.colors.background}
          />
          {family.styles.map((style) => (
            <img
              key={style.id}
              className="Family-stylesBlock-style"
              src={style.imageUrl}
              style={{
                height: family.styleHeight
              }}
            />
          ))}
        </div>

        {family.samplesImageUrl && (
          <>
            <div
              className="Family-rule"
              style={{ background: family.colors.text }}
            >
              <SquircleHeader
                content="samples"
                backgroundColor={family.colors.text}
                textColor={family.colors.background}
              />
            </div>
            <img className="Family-sampleImage" src={family.samplesImageUrl} />
          </>
        )}

        <div className="Family-rule" style={{ background: family.colors.text }}>
          <SquircleHeader
            content="try it"
            backgroundColor={family.colors.text}
            textColor={family.colors.background}
          />
        </div>
      </div>
    </div>
  );
};

export default Family;
