import React, { useState } from 'react';

import TwoColumnRow from 'components/TwoColumnRow';
import DetailRow from 'components/DetailRow';

import TrialFontsForm from './TrialFontsForm';
import FamilyType from './FamilyType';

interface Props {
  family: FamilyType;
}

const MoreDetails: React.FC<Props> = (props) => {
  const { family } = props;

  const [showTrialForm, setShowTrialForm] = useState(false);

  return (
    <DetailRow
      label="Want even more details?"
      textColor={family.colors.background}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .Family-detailsBlock-furtherDetails-button:hover {
              background: ${family.colors.background} !important;
              color: ${family.colors.text} !important;
            }
          `
        }}
      />
      <TwoColumnRow
        rowOne={
          <a
            className="Family-detailsBlock-furtherDetails-button"
            style={{
              color: family.colors.background,
              background: family.colors.text
            }}
            target="_blank"
            rel="noreferrer"
            href={family.specimenPDFUrl || ''}
          >
            PDF Specimen
          </a>
        }
        rowTwo={
          <button
            className="Family-detailsBlock-furtherDetails-button"
            style={{
              color: family.colors.background,
              background: family.colors.text
            }}
            onClick={() => setShowTrialForm(true)}
            disabled={showTrialForm}
          >
            Free Trial Fonts
          </button>
        }
      />
      {showTrialForm && (
        <TrialFontsForm
          familyId={family.id}
          colors={family.colors}
          dismiss={() => setShowTrialForm(false)}
        />
      )}
    </DetailRow>
  );
};

export default MoreDetails;
