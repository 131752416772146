import React, { useEffect, useState } from 'react';

interface Props {
  imageURLs: string[];
  homeHeight: string;
}

const FamilyName: React.FC<Props> = (props) => {
  const { imageURLs, homeHeight } = props;

  const [imageIndex, setImageIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    if (animating) {
      const increment = () => {
        setImageIndex((value) => {
          let newValue = value + 1;
          if (newValue > imageURLs.length - 1) {
            newValue = 1;
          }
          return newValue;
        });
      };

      increment();

      const intervalID = setInterval(increment, 240);

      return () => {
        clearInterval(intervalID);
        setImageIndex(0);
      };
    } else {
      return;
    }
  }, [animating, imageURLs.length]);

  return (
    <img
      style={{ height: homeHeight }}
      src={imageURLs[imageIndex]}
      onMouseEnter={() => setAnimating(true)}
      onMouseLeave={() => setAnimating(false)}
    />
  );
};

export default FamilyName;
