import './Markdown.scss';

import React from 'react';

interface Props {
  children: string;
}

const Markdown: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <div className="Markdown" dangerouslySetInnerHTML={{ __html: children }} />
  );
};

export default Markdown;
