import './TwoColumnRow.scss';

import React from 'react';

interface Props {
  rowOne: React.ReactNode;
  rowTwo: React.ReactNode;
}

const TwoColumnRow: React.FC<Props> = (props) => {
  return (
    <div className="TwoColumnRow">
      <div className="TwoColumnRow-one">{props.rowOne}</div>
      <div className="TwoColumnRow-two">{props.rowTwo}</div>
    </div>
  );
};

export default TwoColumnRow;
