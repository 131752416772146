import './TrialFontsForm.scss';

import React, { useState } from 'react';

import axios from 'axios';

interface Props {
  familyId: number;
  colors: {
    accent: string;
    background: string;
    text: string;
  };
}

const FamilyTrialFontsForm: React.FC<Props> = (props) => {
  const { familyId, colors } = props;

  const [formData, setFormData] = useState({
    email: '',
    agreeToLicense: false
  });

  const [error, setError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [success, setSuccess] = useState(false);

  const updateFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.currentTarget.type === 'checkbox'
        ? event.currentTarget.checked
        : event.currentTarget.value;

    setFormData({
      ...formData,
      [event.currentTarget.name]: value
    });
  };

  const requestTrialFont = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessages([]);
    setError(false);

    axios
      .post('/api/font_trials.json', {
        email: formData.email,
        family_id: familyId
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((error: any) => {
        setErrorMessages(error.response?.data?.errors || []);
        setError(true);
      });
  };

  const submitEnabled = formData.email !== '' && formData.agreeToLicense;

  if (success) {
    return (
      <div className="FamilyTrialFontsForm-successMessage">
        Check your email to download your trial fonts.
      </div>
    );
  }

  return (
    <form className="FamilyTrialFontsForm" onSubmit={requestTrialFont}>
      {error && (
        <div
          className="FamilyTrialFontsForm-errorMessage"
          style={{ backgroundColor: colors.background }}
        >
          There was an error requesting trial fonts. {errorMessages.join(' ')}
        </div>
      )}

      <label>
        Please enter your email address for a link to download trial fonts:
        <input
          type="text"
          name="email"
          value={formData.email}
          onChange={updateFormData}
          className="FamilyTrialFontsForm-emailInput"
          style={{
            backgroundColor: colors.text,
            color: colors.accent
          }}
        />
      </label>

      <label className="FamilyTrialFontsForm-checkboxLabel">
        <input
          type="checkbox"
          name="agreeToLicense"
          checked={formData.agreeToLicense}
          onChange={updateFormData}
          className="FamilyTrialFontsForm-checkbox"
          style={{ borderColor: colors.text, color: colors.text }}
        />
        <div>
          I agree to the terms and service of the{' '}
          <a href="/license">trial license.</a>
        </div>
      </label>

      <style
        dangerouslySetInnerHTML={{
          __html: `
            button.FamilyTrialFontsForm-submitButton:hover {
              background: ${colors.background} !important;
              color: ${colors.text} !important;
            }
          `
        }}
      />

      <button
        type="submit"
        disabled={!submitEnabled}
        style={{
          color: colors.background,
          background: colors.text
        }}
        className="FamilyTrialFontsForm-submitButton"
      >
        Submit
      </button>
    </form>
  );
};

export default FamilyTrialFontsForm;
