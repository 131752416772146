import './Header.scss';

import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { useThrottledScroll } from 'utils/useThrottledScroll';

import Logo from './Logo';
import LogoSmall from './LogoSmall';
import MenuIcon from './MenuIcon';
import XIcon from './XIcon';

interface Props {
  currentOrderItems: number;
  signedIn: boolean;
  rAndDFonts: boolean;
}

const cartIcons = [
  <></>,
  <>&#xe035;</>,
  <>&#xe036;</>,
  <>&#xe037;</>,
  <>&#xe038;</>,
  <>&#xe039;</>,
  <>&#xe03A;</>,
  <>&#xe03B;</>,
  <>&#xe03C;</>,
  <>&#xe03D;</>
];

const Header: React.FC<Props> = (props) => {
  const { currentOrderItems, signedIn, rAndDFonts } = props;

  const onScroll = useCallback(() => {
    if (document.documentElement.scrollTop > 90) {
      document.body.classList.add('fixed-top-bar');
    } else {
      document.body.classList.remove('fixed-top-bar');
    }
  }, []);

  useThrottledScroll(onScroll);

  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);

  return (
    <header
      id="header"
      className={classNames({
        'mobile-menu-expanded': mobileMenuExpanded
      })}
    >
      <nav className="desktop-nav" role="navigation">
        <div
          className="header-mobile-icon-wrapper"
          onClick={() => setMobileMenuExpanded((value) => !value)}
        >
          {mobileMenuExpanded ? <XIcon /> : <MenuIcon />}
        </div>

        <div className="nav-left">
          <a href="/fonts">Fonts</a>
          {rAndDFonts && <a href="/rnd">R&amp;D</a>}
          <a href="/portfolio">Custom</a>
        </div>

        <div className="nav-center">
          <a className="header-logo" href="/">
            <Logo />

            <LogoSmall />
          </a>
        </div>

        <div className="nav-right">
          <a href="/about">About</a>
          <a href="/contact">Contact</a>
        </div>

        <div className="header-sign-in">
          {currentOrderItems > 0 && (
            <a className="icon cart-icon" href="/cart">
              <span>{cartIcons[currentOrderItems] || <>&#xe03E;</>}&nbsp;</span>
              &#xe034;
            </a>
          )}
          {signedIn ? (
            <a href="/account">Account</a>
          ) : (
            <a href="/account/sign_in">Sign In</a>
          )}
        </div>
      </nav>

      <nav className="header-mobile-nav" role="navigation">
        <div>
          <a href="/fonts">Fonts</a>
        </div>
        <div>
          <a href="/portfolio">Commissioned</a>
        </div>
        <div>
          <a href="/about">About</a>
        </div>
        <div>
          <a href="/contact">Contact</a>
        </div>
        <div>
          <a href="/account/sign_in">Sign In</a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
