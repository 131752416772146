import React from 'react';

const MenuIcon: React.FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 26 20"
      xmlSpace="preserve"
      className="menu-icon"
    >
      <g>
        <g>
          <rect width="26" height="2" />
        </g>
        <g>
          <rect y="9" width="26" height="2" />
        </g>
        <g>
          <rect y="18" width="26" height="2" />
        </g>
      </g>
    </svg>
  );
};

export default MenuIcon;
