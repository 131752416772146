import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 87.9 58.9"
      xmlSpace="preserve"
      className="ts-logo-large"
    >
      <g>
        <path
          d="M42.3,34.1c-0.7,0-1.3,0.3-1.9,0.9v4.3c0.5,0.5,1.2,0.7,1.8,0.7c1.2,0,2-0.9,2-2.9
				C44.2,35.2,43.5,34.1,42.3,34.1z"
        />
        <path
          d="M51.4,20.7c0-1.8-0.7-2.9-1.9-2.9c-0.7,0-1.3,0.3-1.9,0.9v4.3c0.5,0.5,1.2,0.7,1.8,0.7
				C50.7,23.6,51.4,22.7,51.4,20.7z"
        />
        <path
          d="M53.1,34.1c-0.7,0-1.3,0.3-1.9,0.9v4.3c0.5,0.5,1.2,0.7,1.8,0.7c1.2,0,2-0.9,2-2.9
				C55.1,35.2,54.4,34.1,53.1,34.1z"
        />
        <path
          d="M43.9,0C13,0,0,12.4,0,29.4c0,17.1,13,29.4,43.9,29.4c30.9,0,44-12.4,44-29.4
				C87.9,12.4,74.8,0,43.9,0z M60.1,15.7c2.9,0,4.6,2.1,4.6,5v0.8h-6.5c0.2,1.4,1.2,2.1,2.6,2.1c0.9,0,1.8-0.3,3.2-1l0.5,2.1
				c-1.1,0.6-2.7,1-3.8,1c-3.3,0-5.3-1.9-5.3-5C55.4,17.8,57.4,15.7,60.1,15.7z M25.1,12.1h10.5v2.5h-3.7v10.8h-3V14.6h-3.7V12.1z
				 M21.2,42c-1.6,0-3.4-0.4-5-1.2l0.7-2.6c1.6,0.9,3,1.2,4,1.2c1.3,0,2-0.5,2-1.4c0-0.7-0.3-1.1-1.4-1.5l-1.5-0.4
				c-2.3-0.7-3.3-1.9-3.3-3.8c0-2.4,1.9-4,4.7-4c1.1,0,2.7,0.3,4.2,1l-0.7,2.6c-1.5-0.8-2.6-1.1-3.6-1.1c-1.1,0-1.8,0.6-1.8,1.3
				c0,0.7,0.4,1.2,1.3,1.4l1.3,0.4c2.9,0.8,3.7,1.9,3.7,4C25.8,40.2,24.1,42,21.2,42z M35.8,41.9h-2.7v-1.5c-0.8,1-1.9,1.7-3.1,1.7
				c-2,0-3-1.3-3-3.8v-6h2.8v6.1c0,1.1,0.4,1.5,1.2,1.5c0.6,0,1.4-0.5,1.9-1.1v-6.5h2.8V41.9z M35.1,29.3v-2.2h1.1
				c0.7,0,1-0.2,1.2-0.8l0.1-0.4l-3.5-10h3l1.9,6.5l1.9-6.5h2.7L40,26.7c-0.7,2-1.7,2.6-3.7,2.6H35.1z M42.9,42c-0.9,0-1.8-0.4-2.5-1
				v4.6h-2.8V32.2h2.6v1.3c0.8-1,1.8-1.4,3-1.4c2.2,0,4,1.9,4,5C47.1,40.2,45.3,42,42.9,42z M47.6,29.2h-2.8V15.9h2.6v1.3
				c0.8-1,1.8-1.4,3-1.4c2.2,0,4,1.9,4,5c0,3.1-1.8,5-4.2,5c-0.9,0-1.8-0.4-2.5-1V29.2z M53.8,42c-0.9,0-1.8-0.4-2.5-1v4.6h-2.8V32.2
				H51v1.3c0.8-1,1.8-1.4,3-1.4c2.2,0,4,1.9,4,5C58,40.2,56.2,42,53.8,42z M62,41.9h-2.8V28.5H62V41.9z M69,43c-0.7,2-1.7,2.6-3.7,2.6
				h-1.2v-2.2h1.1c0.7,0,1-0.2,1.2-0.8l0.1-0.4l-3.5-10h3l1.9,6.5l1.9-6.5h2.7L69,43z"
        />
        <path d="M60.2,17.7c-0.9,0-1.6,0.7-1.9,1.9H62C61.9,18.4,61.2,17.7,60.2,17.7z" />
      </g>
    </svg>
  );
};

export default Logo;
