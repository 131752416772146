import React from 'react';

const LogoSmall: React.FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32.9 22"
      xmlSpace="preserve"
      className="ts-logo-small"
    >
      <path
        d="M16.4,0C4.9,0,0,4.6,0,11c0,6.4,4.9,11,16.4,11c11.6,0,16.4-4.6,16.4-11C32.9,4.6,28,0,16.4,0z
	 M15.8,8.4h-2.7v7.8h-2.2V8.4H8.2V6.6h7.6V8.4z M20.6,16.4c-1.1,0-2.5-0.3-3.6-0.9l0.5-1.9c1.2,0.6,2.2,0.9,2.9,0.9
	c0.9,0,1.4-0.4,1.4-1c0-0.5-0.2-0.8-1-1.1l-1.1-0.3c-1.7-0.5-2.4-1.4-2.4-2.7c0-1.7,1.4-2.9,3.4-2.9c0.8,0,2,0.2,3,0.7L23.3,9
	c-1.1-0.6-1.9-0.8-2.6-0.8c-0.8,0-1.3,0.4-1.3,1c0,0.5,0.3,0.8,1,1l1,0.3c2.1,0.6,2.7,1.4,2.7,2.9C24,15,22.7,16.4,20.6,16.4z"
      />
    </svg>
  );
};

export default LogoSmall;
