export type LicenseTypeID = 'desktop' | 'webfont' | 'mobile';

export const LicenseTypeIDs = ['desktop', 'webfont', 'mobile'];

export interface LicenseType {
  id: LicenseTypeID;
  label: string;
}

export const licenseTypes: LicenseType[] = [
  {
    id: 'desktop',
    label: 'Desktop'
  },
  {
    id: 'webfont',
    label: 'Webfont'
  },
  {
    id: 'mobile',
    label: 'Mobile'
  }
];

export interface LicenseOption {
  id: number;
  quantity: number;
  multiplier: number;
  description: string;
}

export interface LicenseOptions {
  desktop: {
    workstations: LicenseOption;
  };
  webfont: {
    domains: LicenseOption;
    views: LicenseOption;
  };
  mobile: {
    apps: LicenseOption;
  };
}
