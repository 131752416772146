import './DetailRow.scss';

import React from 'react';

interface Props {
  label: string;
  textColor: string;
  children: React.ReactNode;
}

const DetailRow: React.FC<Props> = (props) => {
  return (
    <div className="DetailRow">
      <div className="DetailRow-label" style={{ color: props.textColor }}>
        {props.label}
      </div>
      <div className="DetailRow-content">{props.children}</div>
    </div>
  );
};

export default DetailRow;
