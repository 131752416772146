import React from 'react';

import { useFetchJSON } from 'utils/useFetchJSON';
import { PricingData } from './DataTypes';

import Pricing from './Pricing';

interface Props {
  familySlug: string;
}

const PricingLoader: React.FC<Props> = (props) => {
  const { familySlug } = props;

  const { data } = useFetchJSON<PricingData>(`/api/${familySlug}/pricing`);

  if (data == null) {
    return null;
  }

  return <Pricing familySlug={familySlug} pricingData={data} />;
};

export default PricingLoader;
